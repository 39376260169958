<script>
import Layout from "@/router/layouts/exchange";
import { VclList } from "vue-content-loading";

export default {
  locales: {
    pt: {
      'Extract': 'Extrato',
      'Data': 'Data',
      'Currency': 'Moeda',
      'Category': 'Categoria',
      'Value': 'Valor',
      'Balance after': 'Saldo após',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'Extract': 'Extrato',
      'Data': 'Data',
      'Currency': 'Moeda',
      'Category': 'Categoria',
      'Value': 'Valor',
      'Balance after': 'Saldo após',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: { Layout, VclList },
  data() {
    return {
      filter: {
        currency: {
          value: 'all',
          options: {
            all: 'Todos',
            omc: 'OMC',
            brl: 'Real',
          }
        },
        category: {
          value: 'all',
          options: {
            all: 'Todas',
            buy: 'Compra',
            deposit_wallet_external: 'Depósito de carteira externa',
            withdrawal_wallet_external: 'Retirada para carteira externa',
            withdrawal_bank: 'Retirada para conta bancária',
            mining_fee_priority_low: 'Taxa de mineração, baixa prioridade',
            mining_fee_priority_normal: 'Taxa de mineração, prioridade normal',
            fee_buy: 'Taxa sobre compra',
            fee_sell: 'Taxa sobre venda',
            fee_withdrawal: 'Taxa sobre retirada bancária',
            sell: 'Venda'
          }
        }
      },
      table: {
        heade: [
          'Data', 'Currency', 'Category', 'Value', 'Balance after'
        ],
        body: [
          { create_date: '06/04/2020 12:00', currency: 'Bitcoin', category: 'Depósito de carteira externa', value: '0.09400279', balance: '0.09400279' },
          { create_date: '06/04/2020 12:00', currency: 'Bitcoin', category: 'Depósito de carteira externa', value: '0.09400279', balance: '0.09400279' },
        ],
        loading: false,
        errored: false,
        empty: false,
        modal: false,
      },
      statData: null
    };
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex">
          <h4 class="mb-0">{{ t('Extract') }}</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body d-flex">
            <div class="mr-3">
              <b-input-group :prepend="t('Currency')">
                <b-form-select v-model="filter.currency.value" :options="filter.currency.options"></b-form-select>
              </b-input-group>
            </div>
            <div class="mr-3">
              <b-input-group :prepend="t('Category')">
                <b-form-select v-model="filter.category.value" :options="filter.category.options"></b-form-select>
              </b-input-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="table.empty">{{ t('No records found.') }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap table-striped">
                <thead class="thead-light">
                  <tr>
                    <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td,index) in table.body" :key="index">
                    <td>{{ td.create_date }}</td>
                    <td>{{ td.currency }}</td>
                    <td>{{ td.category }}</td>
                    <td class="text-success">{{ td.value }}</td>
                    <td>{{ td.balance }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="table.modal" :title="t('Bitcoin')" centered>
    </b-modal>
  </Layout>
</template>